import React, { ChangeEvent, useState } from 'react';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { CardsList } from 'src/entities/CardsList/CardsList';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { FiltersWithSearch, SelectValue } from 'src/pages/companyStructure/components/FiltersWithSearch/FiltersWithSearch';
import { matchItems } from 'src/shared/lib/filter';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox/Checkbox';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { Switch } from 'src/shared/ui/_switches/Switch/Switch';
import s from './AddUserModal.module.scss';
import { CreateNewUser } from './CreateNewUser/CreateNewUser';

interface Props {
	departmentId: string;
	toggleModal: () => void;
}

export const AddUserModal: React.FC<Props> = props => {
	const { departmentId, toggleModal } = props;

	// * Queries
	const { currentData: allUsersData, isLoading } = company_structure_serviceAPI.useGetUsersQuery({
		skipcount: 0,
		takecount: 1000,
		isAscendingSort: true,
	});

	const { currentData: customPositions } = company_structure_serviceAPI.useGetPositionsQuery({
		params: {
			skipcount: 0,
			takecount: 1000,
		},
	});

	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});
	const allUserPositions = positionsData?.body || [];
	const allCustomPositions = customPositions?.body || [];
	const allPositions = [...allUserPositions, ...allCustomPositions];

	const [editUser] = company_structure_serviceAPI.useEditUserMutation();

	const customUsers =
		allUsersData?.body
			.filter(user => user.isCustom && user.role === 'Employee')
			.map(user => {
				const fullNameArr = user.fullName.split(' ');

				return {
					...user,
					name: `${fullNameArr[0]} ${fullNameArr[1]}`,
				};
			}) || [];

	const onAddUser = (usersId: string[]) => {
		if (usersId.length === 0) return;

		// если добавляемый сотрудник - директор, убираем его assignment: Director
		const directors = usersId.filter(id => {
			const arrays = customUsers.find(item => item.id === id && item.assignment === 'Director');
			return arrays ? arrays.id === id : false;
		});

		usersId.map(id => {
			editUser({
				userId: id,
				payload: [
					{
						op: 'replace',
						path: '/departmentId',
						value: departmentId,
					},
				],
			})
				.unwrap()
				.then(() => {
					if (directors.length > 0) {
						directors.map(directorId => {
							editUser({
								userId: directorId,
								payload: [
									{
										op: 'replace',
										path: '/assignment',
										value: 'Employee',
									},
								],
							});
						});
					}
				})
				.catch(err => console.log(err));
		});

		toggleModal();
	};

	const clickCancelButton = () => {
		setSelectedUsers([]);
		toggleModal();
	};

	// * Add users
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

	const addNameUser = (selectedUserId: string) => {
		const isExistUser = selectedUsers.find(id => id === selectedUserId);
		if (isExistUser) return;
		setSelectedUsers([...selectedUsers, selectedUserId]);
	};

	const deleteNameUser = (selectedUserId: string) => {
		setSelectedUsers(selectedUsers.filter(id => id !== selectedUserId));
	};

	// * Switch
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		event.stopPropagation();
		setIsSelected(prevState => !prevState);
	};

	// * Filter
	const [position, setPosition] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');
	const [valuePosition, setValuePosition] = useState('');

	const usersFilter = customUsers?.filter(item => {
		if (position.name) {
			return item.position === position.name;
		}
		return item;
	});

	const usersFilterName = matchItems(usersFilter, userName);

	// * Modal
	const [showCreateModal, setShowCreateModal] = useState(false);

	const toggleCreateModal = () => setShowCreateModal(prevState => !prevState);

	// * Render
	return (
		<div>
			<div className={s.title}>
				<h2>Добавление сотрудника</h2>
			</div>

			{allPositions.length > 0 && (
				<div className={s.filter_block}>
					<FiltersWithSearch
						selectPlaceholder="Должность"
						selectOptions={allPositions}
						selectedOption={position}
						onSelectChange={(value: SelectValue) => setPosition(value)}
						searchPlaceholder="Поиск сотрудника по ФИО"
						searchValueOption={valuePosition}
						onChangeSearchOption={setValuePosition}
						searchValue={userName}
						onSearchChange={setUserName}
						isNullable
					/>
				</div>
			)}

			<div className={s.selected_block}>
				<SelectedCount
					title="Выбрано сотрудников"
					count={selectedUsers.length}
				/>

				<div className={s.selected_switch}>
					<div className={s.switch_text}>Просмотреть выбранных</div>

					<Switch
						checked={isSelected}
						onChange={handleChangeSwitch}
						disabled={selectedUsers.length === 0}
					/>
				</div>
			</div>

			<div className={s.create_user_button}>
				<AddButton
					title="Создать сотрудника"
					onClick={toggleCreateModal}
				/>
			</div>

			{showCreateModal && (
				<Modal
					show={showCreateModal}
					toggleModal={toggleCreateModal}
					width={800}
					overflowX="auto"
					zIndex={2}
					Content={
						<CreateNewUser
							isNewUser
							toggleCreateModal={toggleCreateModal}
						/>
					}
				/>
			)}

			<div className={s.list}>
				{customUsers.length > 0 && (
					<CardsList
						columns={[
							{
								title: 'ФИО',
								width: 10,
							},
							{
								title: 'ДЕПАРТАМЕНТ',
								width: 20,
							},
						]}
						cards={usersFilterName
							.filter(user => {
								if (!isSelected) return user;
								return selectedUsers.find(item => item === user.id);
							})
							.map(user => ({
								id: user.id,
								columns: [
									{
										Value: (
											<div className={s.about}>
												<span className={s.about_name}>{user.name}</span>
												<span className={s.about_position}>{user.position}</span>
											</div>
										),
										width: 10,
									},
									{
										Value: <span>{user.departmentName}</span>,
										width: 20,
									},
								],
								leftComponent: {
									width: 6,
									Component: (
										<>
											<div style={{ marginRight: '24px' }}>
												<Checkbox
													checked={!!selectedUsers.find(item => item === user.id)}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const checked = e.target.checked;
														if (checked) {
															addNameUser(user.id);
														} else {
															deleteNameUser(user.id);
														}
													}}
												/>
											</div>
											<Avatar
												name={user.name}
												avatarId={user.avatarId}
											/>
										</>
									),
								},
								style: selectedUsers.find(item => item === user.id) ? { background: '#F2F4FC' } : {},
							}))}
					/>
				)}
			</div>

			<ButtonPair
				primaryText="Сохранить"
				secondaryText="Отменить"
				primaryOnClick={() => onAddUser(selectedUsers)}
				secondaryOnClick={() => clickCancelButton()}
				primaryIsLoading={isLoading}
				secondaryIsLoading={isLoading}
			/>
		</div>
	);
};
