import { FC, useState } from 'react';
import { DepartmentInfo } from 'src/app/redux/state/department/department/types';
import { useGetDepartmentFindQuery, usePostUserCreateMutation } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';
import { SelectStringSearchFilter } from 'src/entities/_filters/SelectStringSearchFilter/SelectStringSearchFilter';
import { fullNameSearch } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { UsersTable } from '../UsersTable';
import s from './AddTeamMember.module.scss';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';

interface Props {
	departmentId: string;
	departmentUsersIds: string[];
}

export const AddTeamMember: FC<Props> = props => {
	const { departmentId, departmentUsersIds } = props;

	// * API
	// - Departments
	const { data: dataDepartments, isLoading: isLoadingGetDepartments } = useGetDepartmentFindQuery({
		takecount: 1000,
		skipcount: 0,
	});

	const departments = dataDepartments?.body;

	let departmentOptions: Array<Pick<DepartmentInfo, 'id' | 'name'> | { id: string | null; name: string }> = [
		{
			id: null,
			name: '',
		},
	];

	if (departments) {
		departmentOptions = departmentOptions.concat(
			departments?.map(department => ({
				id: department.id,
				name: department.name,
			})),
		);
	}

	// - Users
	const { data, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const users = data?.body;

	const isLoaded = !!users;

	// - Add
	const [addUsersToDepartment, { isLoading: isLoadingAddUser }] = usePostUserCreateMutation();

	// - Loading status
	const isLoading = isLoadingGetDepartments || isLoadingGetUsers || isLoadingAddUser;

	// * Filters
	const [filterDepartment, setFilterDepartment] = useState<(typeof departmentOptions)[number]>({
		id: null,
		name: '',
	});

	const [filterName, setFilterName] = useState<string>('');

	// * Modal
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(prevState => !prevState);

	const closeModal = () => {
		setSelectedUsers([]);
		setViewOnlySelected(false);
		setFilterName('');
		setFilterDepartment({
			id: null,
			name: '',
		});
		toggleModal();
	};

	// * Selected users
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [viewOnlySelected, setViewOnlySelected] = useState<boolean>(false);

	// * filtered users
	const filteredUsers = users
		? users
				.filter(({ user }) => (viewOnlySelected ? selectedUsers.includes(user.id) : true))
				.filter(({ user }) => !departmentUsersIds.includes(user.id)) // Убирает пользователей находящихся в департаменте из списка.
				.filter(({ user }) => fullNameSearch(user, filterName))
				.filter(({ departmentUser }) => (filterDepartment.id === null ? true : departmentUser?.department.id === filterDepartment.id))
		: [];

	// * Buttons actions
	const addUsers = () =>
		addUsersToDepartment({
			createDepartmentUsersRequest: {
				departmentId,
				users: selectedUsers.map(userId => ({
					userId,
					role: 'Employee',
					assignment: 'Employee',
				})),
			},
		})
			.unwrap()
			.then(() => closeModal())
			.catch(e => console.log(e));

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={modal}
				onClose={closeModal}
			>
				<div className={s.container}>
					<div className={s.top}>
						<Heading
							level={2}
							marginBottom="l"
						>
							Добавить сотрудника
						</Heading>

						<div className={s.selected}>
							<SelectedCount
								title="Выбрано сотрудников"
								count={selectedUsers.length}
							/>
							<BooleanSwitch
								text="Просмотреть выбранных"
								isLeftText={true}
								checked={viewOnlySelected}
								onChange={() => setViewOnlySelected(prevValue => !prevValue)}
								disabled={selectedUsers.length === 0}
							/>
						</div>

						<SelectStringSearchFilter
							selectValue={filterDepartment}
							setSelect={setFilterDepartment as (value: { id: null | string; name: string | null }) => void}
							selectPlaceholder="Департамент"
							selectOptions={departmentOptions}
							searchStringValue={filterName}
							setSearchString={setFilterName}
							searchPlaceholder="Поиск по ФИО"
						/>
					</div>

					<div className={s.users}>
						<ConditionalRendering
							initialLoading={isLoading}
							isSuccessful={isLoaded}
							LoadedResult={
								<UsersTable
									filteredUsers={filteredUsers}
									selectedUsers={selectedUsers}
									setSelectedUsers={setSelectedUsers}
								/>
							}
						/>
					</div>

					<ButtonPair
						primaryText="Добавить"
						primaryIsLoading={isLoading}
						primaryOnClick={addUsers}
						secondaryText="Отменить"
						secondaryOnClick={closeModal}
						secondaryIsLoading={isLoading}
					/>
				</div>
			</ModalNewProto>

			<AddButton
				title="Добавить сотрудника"
				onClick={toggleModal}
				className={s.add_user}
			/>
		</>
	);
};
