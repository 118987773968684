import React, { useState } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { UpdateInfo } from 'src/pages/companyStructure/components/UpdateInfo/UpdateInfo';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { useRights } from 'src/shared/hooks/useRights';
import { dateToFullRuDateString } from 'src/shared/lib/date';
import { ConditionalRendering } from 'src/shared/providers';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { LineTabs, SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';
import { FiltersWithSearch, SelectValue } from '../components/FiltersWithSearch/FiltersWithSearch';
import { CompanyUsersList } from './CompanyDetails/components/CompanyUsersList/CompanyUsersList';
import s from './CompanyStructurePage.module.scss';
import { AdminstrationContent } from './Content/AdministrationContent/AdministrationContent';
import { UnitsContent } from './Content/UnitsContent/UnitsContent';

export const CompanyStructurePage: React.FC = () => {
	// * Rights
	const isAdmin = useRights();

	// * Tabs navigation
	const tabs: SingleTab[] = [
		{
			id: 'units',
			name: 'Организационные единицы',
		},
		{
			id: 'administration',
			name: 'Администрация',
		},
	];

	const [activeTabId, setActiveTabId] = useState<string>('units');

	// * Selectors
	const { reorderedUnits } = useAppSelector(state => state.company_structure_service.departments);
	const { reorderedAdministrators } = useAppSelector(state => state.company_structure_service.users);

	// * API
	const [changePriority] = company_structure_serviceAPI.useChangePriorityMutation();
	const [changeAdministratorPriority] = company_structure_serviceAPI.useChangeAdministratorPriorityMutation();
	const [putSurveyStatisticsParameters] = company_structure_serviceAPI.usePutSurveyStatisticsParametersMutation();

	// * Queries
	const { data: dateInfo } = company_structure_serviceAPI.useGetSurveyStatisticsParametersQuery({
		ParameterName: 'ValidFor',
	});

	// * All users
	const [
		getUsers, //
		{ currentData: allUsersData, isFetching: isSearchLoading, isUninitialized },
	] = company_structure_serviceAPI.useLazyGetUsersQuery();
	const searchUsers = allUsersData?.body || [];

	const { currentData: adminsData } = company_structure_serviceAPI.useGetUsersQuery({
		skipcount: 0,
		takecount: 1000,
		isAdministration: true,
	});

	// - Positions
	const { currentData: customPositions } = company_structure_serviceAPI.useGetPositionsQuery({
		params: {
			skipcount: 0,
			takecount: 1000,
		},
	});

	const { currentData: positionsData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});

	const allUserPositions = positionsData?.body || [];
	const allCustomPositions = customPositions?.body || [];
	const allPositions = [...allUserPositions, ...allCustomPositions];

	// * Filter
	const [position, setPosition] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');
	const [valuePosition, setValuePosition] = useState('');

	const isSearching = !!(position.id || userName);

	// * Search user
	useDebounceEffect(
		() => {
			if (!isSearching) return;

			getUsers({
				skipcount: 0,
				takecount: 1000,
				isAscendingSort: true,
				nameIncludeSubstring: userName,
				positionId: position.id || undefined,
			});
		},
		500,
		[position, userName],
	);

	// * Edit
	const [edit, setEdit] = useState<boolean>(false);
	const [date, setDate] = useState<Date | null>(null);

	const editStructure = () => setEdit(prev => !prev);

	const saveStructure = () => {
		editStructure();

		const isChangeUnits = Object.values(reorderedUnits).length > 1;
		const isChangeAdministrators = Object.values(reorderedAdministrators).length > 1;

		const dateString = date && dateToFullRuDateString(date);
		const isChangeDate = dateInfo !== dateString;

		if (!isChangeUnits && !isChangeAdministrators && !isChangeDate) return;

		isChangeUnits &&
			changePriority({
				payload: {
					prioritiesToDepartments: reorderedUnits,
				},
			});

		isChangeAdministrators &&
			changeAdministratorPriority({
				payload: {
					prioritiesToUsers: reorderedAdministrators,
				},
			});

		isChangeDate &&
			dateString &&
			putSurveyStatisticsParameters({
				parameterName: 'ValidFor',
				body: dateString,
			});
	};

	return (
		<PageDecorator>
			<div className={s.container}>
				<div className={s.header}>
					<div className={s.title}>
						<h1>Структура компании</h1>

						{isAdmin && !isSearching && edit && (
							<div className={s.edit_buttons}>
								<Button
									variant="tertiary"
									onClick={editStructure}
								>
									Отменить
								</Button>

								<Button onClick={saveStructure}>Сохранить</Button>
							</div>
						)}

						{isAdmin && !isSearching && !edit && (
							<Button
								variant="tertiary"
								onClick={editStructure}
							>
								Редактировать
							</Button>
						)}
					</div>

					<UpdateInfo
						date={date}
						setDate={setDate}
						isEdit={edit}
					/>
				</div>

				{!edit && allPositions.length > 0 && (
					<FiltersWithSearch
						selectPlaceholder="Должность"
						selectOptions={allPositions}
						selectedOption={position}
						onSelectChange={(value: SelectValue) => setPosition(value)}
						searchPlaceholder="Поиск сотрудника по ФИО"
						searchValueOption={valuePosition}
						onChangeSearchOption={setValuePosition}
						searchValue={userName}
						onSearchChange={setUserName}
						isNullable
					/>
				)}

				{isSearching && !isUninitialized ? (
					<ConditionalRendering
						initialLoading={isSearchLoading}
						isSuccessful={searchUsers.length > 0}
						LoadedResult={
							<CompanyUsersList
								departmentName
								users={searchUsers.filter(user => !adminsData?.body.find(item => item.id === user.id))}
								edit={isAdmin}
							/>
						}
						FailedResult={<EmptyData />}
					/>
				) : (
					<>
						<LineTabs
							tabs={tabs}
							width="fit"
							activeTabId={activeTabId}
							setActiveTabId={setActiveTabId}
						/>

						{activeTabId === 'units' && <UnitsContent isEdit={edit} />}

						{activeTabId === 'administration' && <AdminstrationContent isEdit={edit} />}
					</>
				)}
			</div>
		</PageDecorator>
	);
};
