import { FC } from 'react';
import { useGetDepartmentsTreeGetQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { UserListItem } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { AddUsersMC } from 'src/features/_modal_contents/AddUsersMC';

interface Props {
	toggleModal: () => void;
	allUsers: UserListItem[];
}

export const AddUserMCContainer: FC<Props> = props => {
	const {
		toggleModal, //
		allUsers,
	} = props;

	// * Selectors
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo);

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	// * API
	const { data: departmentTreeData, isLoading: isLoadingDepartmentTree } = useGetDepartmentsTreeGetQuery({});
	const departmentTree = departmentTreeData?.body ?? [];
	const allDepartments =
		departmentTree.length > 0
			? departmentTree
					.map(department => {
						return department.children ? [department, ...department.children] : department;
					})
					.flat()
			: [];

	// * Selected users
	const setSelectedUsers = (userIds: string[]) => {
		dispatch(setSelectedUsersInfo([...selectedUsersInfo.users, ...allUsers.filter(user => userIds.includes(user.id))]));
	};

	// * Render
	return (
		<>
			<AddUsersMC
				title="Пригласить"
				allUsers={allUsers.filter(user => !selectedUsersInfo.users.find(existingUser => existingUser.id === user.id))}
				allDepartments={allDepartments}
				setSelectedUsers={setSelectedUsers}
				isLoading={false} // Мы сохраняем в Редакс, загрузки нет.
				toggleModal={toggleModal}
			/>
		</>
	);
};
