import React, { useEffect, useState } from 'react';
import { RequestStatus } from 'src/app/api/api_types';
import { departmentServiceActions } from 'src/app/redux/state/department/actions';
import { userServiceActions } from 'src/app/redux/state/user/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { useRights } from 'src/shared/hooks/useRights';
import { matchItems } from 'src/shared/lib/filter';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton/AddButton';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AddUserMC } from './AddUserMC/ui/AddUserMCContainer/AddUserMCContainer';
import s from './UsersContent.module.scss';
import { UsersListContainer } from './UsersListContainer';

export const UsersContent: React.FC = () => {
	// * Rights
	const allowedToEditOffices = useRights(8);

	// * Selectors
	const userIds = useAppSelector(state => state.office_service.office.userIds);
	const companyUsers = useAppSelector(state => state.user_service.user.companyUsers);

	const departments = useAppSelector(state => state.department_service.departmentTree.departments);

	const status = useAppSelector(state => state.user_service.user.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { getCompanyUsers } = userServiceActions.user.async;
	const { clearCompanyUsers } = userServiceActions.user.actions;
	const { getDepartmentsTree } = departmentServiceActions.departmentTree.async;

	// * Initialize
	useEffect(() => {
		userIds && userIds?.length > 0
			? dispatch(
					getCompanyUsers({
						usersParams: userIds.map(userId => ({
							userId,
							includeRole: false,
							includeDepartment: true,
							includeAvatars: true,
							includePosition: true,
						})),
					}),
				)
			: dispatch(clearCompanyUsers());

		userIds && userIds?.length > 0 && dispatch(getDepartmentsTree());
	}, [userIds, userIds?.length]);

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * All company users
	const allUsers =
		companyUsers?.map(companyUser => {
			const { body } = companyUser;
			const { user, departmentUser } = body;
			return {
				id: user.id,
				name: createFullName({ lastName: user.lastName, firstName: user.firstName, middleName: user.middleName || '' }),
				department: departmentUser?.department.name,
				position: body.position?.name,
				avatar: user.avatar,
			};
		}) || [];

	// * Filter
	const [department, setDepartment] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');

	const usersFilter = allUsers.filter(item => {
		if (department.name) {
			return item.department === department.name;
		}
		return item;
	});

	const filteredUsers = matchItems(usersFilter, userName);

	const allDepartments =
		departments.length > 0 &&
		departments
			.map(department => {
				return department.children ? [department, ...department.children] : department;
			})
			.flat();

	// * Conditions
	const isLoading = status === RequestStatus.loading;

	// * Render
	return (
		<>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUserMC toggleModal={toggleModal} />
				</ModalNewProto>
			)}

			<div className={s.container}>
				{allowedToEditOffices && (
					<AddButton
						title="Добавить сотрудника"
						onClick={toggleModal}
					/>
				)}

				{allDepartments && companyUsers.length > 0 && (
					<SelectStringSearchFilter
						selectPlaceholder="Департамент"
						selectValue={department}
						setSelect={(value: SelectValue) => setDepartment(value)}
						selectOptions={allDepartments}
						searchPlaceholder="Поиск сотрудников"
						searchStringValue={userName}
						setSearchString={setUserName}
					/>
				)}

				{isLoading ? <LoaderCircle /> : <>{filteredUsers.length > 0 && <UsersListContainer users={filteredUsers} />}</>}
			</div>
		</>
	);
};
