import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostBookingCreateMutation } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { BookingInfo } from 'src/app/redux/state/office/booking/types';
import { Room } from 'src/app/redux/state/office/room/types';
import { Office } from 'src/app/redux/state/office/types';
import { WorkSpaceInfo } from 'src/app/redux/state/office/workspace/types';
import { UserInfo } from 'src/app/redux/state/user/user/types';
import { useRights } from 'src/shared/hooks/useRights';
import { Button } from 'src/shared/ui/_buttons/Button/Button';
import { ChipList } from 'src/shared/ui/_chips/ChipList/ChipList';
import { SelectSingleWithFilterField } from 'src/shared/ui/_fields/SelectSingleWithFilterField';
import { SwitchField } from 'src/shared/ui/_fields/SwitchField';
import { BookingInfoCard } from '../../../BookingInfoCard';
import s from './PartialBookingMC.module.scss';
import { DateField } from 'src/shared/ui/_fields/DateField';
import { getDiffBetweenDates, isLessThenMax } from 'src/shared/lib/date';
import { getMinDateBooking, getMaxDateBooking } from 'src/pages/booking/lib';

export interface SelectedCellInfoPartial {
	workspace: WorkSpaceInfo;
	selectedDate: Date;
	workspaceBookingList: BookingInfo[];
}

interface Props {
	selectedCellInfo: SelectedCellInfoPartial;
	userInfo: UserInfo;
	office: Office;
	room: Room;
	closeModal: () => void;
	openForbidModal: (selectedCellInfo: SelectedCellInfoPartial) => void;
}

export const PartialBookingMC: React.FC<Props> = props => {
	const {
		selectedCellInfo: { selectedDate, workspace, workspaceBookingList },
		userInfo,
		office,
		room,
		closeModal,
		openForbidModal,
	} = props;

	const maxDate = getMaxDateBooking(workspaceBookingList, selectedDate);

	const userName = `${userInfo.user.firstName} ${userInfo.user.lastName}`;

	// * API
	const [createBooking, { isLoading }] = usePostBookingCreateMutation();
	const { data: usersList } = user_serviceAPI.useFindUsersQuery({
		params: {
			skipCount: 0,
			takeCount: 1000,
			isActive: true,
		},
	});

	// * Rights
	const isAdmin = useRights();

	// * Form
	const formMethods = useForm<any>({
		defaultValues: {
			user: {
				id: userInfo.user.id,
				name: userName,
			},
			startTime: selectedDate,
			endTime: selectedDate,
			isInfiniteBooking: false,
			workspaceId: workspace.id,
		},
	});

	const { handleSubmit, watch, setValue } = formMethods;

	const isInfiniteBooking = watch('isInfiniteBooking');
	const user = watch('user');
	const selectedStartDate = watch('startTime');
	const selectedEndDate = watch('endTime');

	useEffect(() => {
		// - Если дата окончания меньше выбранной даты начала, меняем дату окончания
		const isEndLessStart = isLessThenMax(selectedEndDate, selectedStartDate);
		if (isEndLessStart) setValue('endTime', selectedStartDate);
	}, [selectedStartDate]);

	useEffect(() => {
		if (isInfiniteBooking) {
			setValue('endTime', null);
		} else {
			setValue('endTime', selectedStartDate);
		}
	}, [isInfiniteBooking]);

	const onSubmit = (values: any) => {
		const startTime = values.startTime.toLocaleString('sv');
		const endTime = values.isInfiniteBooking
			? null //
			: DateTime.fromJSDate(new Date(values.endTime)).plus({ day: 1 }).toJSDate().toLocaleString('sv');

		createBooking({
			createBookingRequest: {
				startTime,
				endTime,
				userId: isAdmin ? values.user.id : undefined,
				parentId: values.workspaceId,
			},
		})
			.unwrap()
			.then(() => closeModal())
			.catch(error => console.log('error', error));
	};

	const options =
		usersList &&
		usersList.body.length > 0 &&
		usersList.body.map(user => ({
			id: user.user.id,
			name: `${user.user.firstName} ${user.user.lastName}`,
		}));

	// * Forbid booking
	const onForbidBookingClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		closeModal();
		openForbidModal({ selectedDate, workspace, workspaceBookingList });
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<h3 className={s.title}>{workspace.name}</h3>

				<div className={s.inner}>
					{isAdmin && options && (
						<SelectSingleWithFilterField
							name="user"
							label="Кому"
							options={options}
							sortOptions
							isNullable={false}
						/>
					)}

					<div className={s.dates}>
						<DateField
							label="Дата начала"
							name="startTime"
							minDate={getMinDateBooking(workspaceBookingList, selectedDate)}
							maxDate={maxDate ? DateTime.fromJSDate(new Date(maxDate)).minus({ day: 1 }).toJSDate() : null}
						/>

						<DateField
							label="Дата окончания"
							name="endTime"
							minDate={selectedStartDate}
							maxDate={maxDate ? DateTime.fromJSDate(new Date(maxDate)).minus({ day: 1 }).toJSDate() : null}
							disabled={isInfiniteBooking}
						/>
					</div>

					<SwitchField
						name="isInfiniteBooking"
						text="Без даты окончания"
					/>

					<div className={s.row}>
						<BookingInfoCard
							style={{ width: '100%' }}
							date={selectedDate}
							startTime={isInfiniteBooking ? '00:00' : room.roomType.startTime.slice(0, -3)}
							endTime={isInfiniteBooking ? null : room.roomType.endTime.slice(0, -3)}
							roomName={room.name}
							address={office.address}
							workspaceName={workspace.name}
							userName={user.name}
							readonly
							startDate={selectedStartDate}
							endDate={DateTime.fromJSDate(new Date(selectedEndDate)).plus({ day: 1 }).toJSDate()}
						/>
					</div>

					{workspace.tags.length > 0 && <ChipList chips={workspace.tags} />}
				</div>

				<div className={s.row}>
					{isAdmin && (
						<Button
							type="button"
							variant="tertiary"
							isLoading={isLoading}
							onClick={onForbidBookingClick}
							wide
						>
							Запрет бронирования
						</Button>
					)}

					<Button
						type="submit"
						isLoading={isLoading}
						fixedWidth
					>
						Забронировать
					</Button>
				</div>
			</FormProvider>
		</form>
	);
};
